body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 1rem;
}

:root {
  --chat-min-width: 300px;
  --chat-min-height: 400px;
  --chat-max-width: 500px;
  --chat-max-height: 800px;
}

.App {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url('./TSC_chaDbot_background.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.content-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: var(--chat-max-width);
  margin-top: 10px;
  margin-bottom: 10px;
}

.chat-container {
  position: relative; /* Ensure this is set */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-width: var(--chat-max-width);
  margin: 0 auto;
  background-color: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  box-sizing: border-box;
}

.logo {
  position: absolute; /* Default for desktop */
  top: calc(10px + env(safe-area-inset-top));
  left: 10px;
  max-width: 100px;
  height: auto;
  z-index: 3;
}

.hamburger-menu {
  position: absolute; /* Default for desktop */
  top: calc(10px + env(safe-area-inset-top));
  right: 10px;
  z-index: 1000;
  cursor: pointer;
}

.hamburger-menu img {
  width: 2.5rem;
  height: 2.5rem;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  margin-top: 65px;
  margin-bottom: 105px;
  -webkit-overflow-scrolling: touch;
}

.user-message, .assistant-message {
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  max-width: 80%;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  font-size: 1rem;
}

.user-message {
  align-self: flex-end;
  background-color: #73f5ff;
  color: #141213;
  margin-left: auto;
}

.assistant-message {
  align-self: flex-start;
  background-color: #141213;
  color: #FFFFFF;
  margin-right: auto;
}

.assistant-message a {
  color: #ff5910; /* Link color in bot messages */
  text-decoration: underline;
}

.user-message a {
  color: #000000; /* Link color in user messages */
  text-decoration: underline;
}

/* General link style */
a {
  color: #ff5910; /* TSC orange color */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* New styles for visited links */
.assistant-message a:visited {
  color: #ffbdae; /* A lighter shade of the TSC orange */
}

.user-message a:visited {
  color: #ffbdae; /* A darker shade for contrast */
}

a:visited {
  color: #ffbdae; /* A darker shade of the TSC orange */
}
.input-container {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  margin: 20px;
  border: 1px solid #d1d1c6;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  width: calc(100% - 40px);
  box-sizing: border-box;
  position: absolute;
  bottom: 10px;
  backdrop-filter: blur(5px);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  height: auto;
}

.message-input {
  flex-grow: 1;
  padding: 10px 40px 10px 5px;
  border: none;
  border-radius: 2px;
  resize: none;
  overflow-y: auto;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-family: 'Arial', sans-serif;
  height: 3.75rem; /* Two lines tall for desktop */
  min-height: 3.75rem;
  max-height: 3.75rem;
  width: calc(100% - 50px);
  background-color: transparent;
  line-height: 1.5;
}

.message-input.collecting-info {
  border: 2px solid #141213;
  background-color: #FFF5F2;
}

.message-input:focus {
  outline: none;
}

.send-arrow {
  position: absolute;
  right: 15px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.3125rem;
}

.send-arrow img {
  width: 1.5rem;
  height: 1.5rem;
}

.send-arrow:hover {
  opacity: 0.8;
}

.typing-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}

.typing-indicator .dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #999;
  border-radius: 50%;
  margin: 0 0.125rem;
  animation: blink 1s infinite alternate;
}

@keyframes blink {
  from { opacity: 0.2; }
  to { opacity: 1; }
}

.constant-prompts {
  display: none;
}

.prompt-box {
  background-color: #ffbdae;
  border: 1px solid #ffbdae;
  margin-bottom: 0.625rem;
  padding: 1.0rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
  color: #141213;
}

.prompt-box:hover {
  background-color: #ff5910;
  color: white;
}

.prompt-box.animate {
  transform: translateX(20px);
}

.prompt-box strong {
  display: block;
  margin-bottom: 0;
}

.prompt-box p {
  margin: 0;
}

.prompt-box.large {
  width: 100%;
}

.prompt-box.large:hover {
  background-color: #ff5910;
}

.prompt-box-row {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.prompt-box.small {
  width: calc(50% - 0.25rem);
  background-color: #d1d1c6;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prompt-box.small:hover {
  background-color: #ff5910;
}

.prompt-box-icon {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.prompt-box-icon.show {
  opacity: 1;
}

.menu-content {
  position: absolute;
  top: 3.75rem;
  right: 0.625rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #d1d1c6;
  border-radius: 0.25rem;
  padding: 0.625rem;
  z-index: 1000;
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-layout {
  display: grid;
  gap: 40px;
}

.tsc-form {
  width: 100%;
  max-width: 350px;
  margin-left: 0;
  background-color: #141213;
  color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 0.3125rem;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
}

.form-group input,
.form-group select,
.form-group textarea,
.tsc-form button {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #d1d1c6;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

.form-group input,
.form-group select,
.form-group textarea {
  background-color: white;
  color: black;
}

.tsc-form button {
  background-color: #ff5910;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 0.625rem;
  transition: background-color 0.3s ease;
}

.tsc-form button:hover {
  background-color: #ff5910;
}

.tsc-form h2 {
  font-size: 1.25rem;
  margin-bottom: 0.9375rem;
  color: white;
}

.message.assistant video {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.assistant video:hover {
  cursor: pointer;
  opacity: 0.8;
}
/*.. (rest of the styles remain unchanged) ... */

@media (max-width: 768px) {
  .input-container {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 10px 15px 10px 10px;
      border-radius: 10px;
      bottom: 10px;
  }

    .message-input {
      height: 2.5rem; /* Single line height for mobile */
      min-height: 2.5rem;
      max-height: 2.5rem;
      font-size: 16px; /* Prevents zoom on mobile */
      padding: 0.5rem 35px 0.5rem 0.5rem;
      width: calc(100% - 45px);
      line-height: 1.5;
  }

  .chat-container {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      margin: 0;
  }

  .content-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: var(--chat-max-width);
      margin-top: 0;
      margin-bottom: 0;
  }

  .logo {
      position: fixed; 
      top: calc(10px + env(safe-area-inset-top)); 
      left: 10px;
      max-width: 75px;
      height: auto;
      z-index: 1000; 
  }

  .hamburger-menu {
      position: fixed;
      top: calc(10px + env(safe-area-inset-top));
      right: 10px;
      z-index: 1000;
      cursor: pointer;
  }

  .menu-content {
      position: fixed;
      top: calc(10px + env(safe-area-inset-top) + 50px);
      right: 10px;
      z-index: 1000;
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid #d1d1c6;
      border-radius: 0.25rem;
      padding: 0.625rem;
      backdrop-filter: blur(5px);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .messages {
      margin-top: 140px;
      margin-bottom: 75px; 
  }

  .send-arrow {
      /* ... existing styles ... */
      right: 12px; /* Adjusted position for mobile */
    }

}

.clickable {
  cursor: pointer;
}
