.subscribe-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.subscribe-form {
  position: relative;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Match this to ContactForm's max-width */
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  font-size: 14px;
  padding-top: 40px; /* Add space for the close button */
}

.close-button-container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 10;
}

.close-button {
  width: 100%;
  height: 100%;
  background-color: #ff5910;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  background-color: #e64d0e;
}

.subscribe-form h2 {
  font-size: 20px;
  margin-top: 0;
  color: #141213;
  margin-bottom: 20px;
  text-align: center;
}

.subscribe-form .form-group {
  margin-bottom: 15px; /* Reduce from 20px if that was the previous value */
}

.subscribe-form label {
  display: block;
  margin-bottom: 5px;
  color: #141213;
  font-weight: bold;
  font-size: 14px;
}

.subscribe-form input,
.subscribe-form textarea {
  width: 100%;
  padding: 8px;
  border: 2px solid #d1d1c6;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
}

.subscribe-form input::placeholder,
.subscribe-form textarea::placeholder {
  color: #999;
  font-style: italic;
}

.subscribe-form input:focus,
.subscribe-form textarea:focus {
  outline: none;
  border-color: #088ba0;
}

.subscribe-form textarea {
  height: 100px;
  resize: vertical;
}

.subscribe-form button {
  background-color: #ff5910;
  color: #FFFFFF;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-transform: uppercase;
}

.subscribe-form button:hover {
  background-color: #e64d0e;
}

@media (max-width: 480px) {
  .subscribe-form {
    padding: 20px;
  }
}

/* Additional styles for checkboxes */
.checkbox-group {
  border: 2px solid #d1d1c6; /* Match the border of other input fields */
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 14px;
}

.checkbox-group p {
  color: #141213; /* Match the color of other labels */
  font-size: 16px; /* Match the size of other labels */
  margin-bottom: 10px;
  font-weight: bold;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: normal; /* Remove bold from checkbox labels */
  font-size: 14px;
}

/* Remove any extra margins on the last checkbox */
.checkbox-group label:last-child {
  margin-bottom: 0;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d1d1c6;
  border-radius: 3px;
  outline: none;
  transition: border-color 0.3s;
  position: relative;
}

.checkbox-group input[type="checkbox"]:checked {
  border-color: #ff5910;
  background-color: #ff5910;
}

.checkbox-group input[type="checkbox"]:checked::before {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox-group input[type="checkbox"]:hover {
  border-color: #ff5910;
}

/* Add this if you want to specifically target the area above the checkboxes */
.subscribe-form .form-group:last-of-type {
  margin-bottom: 10px; /* Further reduce space after the last input field */
}

/* Ensure the "Pick your poison" text is close to the checkboxes */
.checkbox-group p {
  color: #141213; /* or your chosen color */
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

/* In SubscribeForm.css */

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.subscribe-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-button {
  width: 100%;
  height: 100%;
  background-color: #ff5910;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  background-color: #e64d0e;
}

.subscribe-form h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}