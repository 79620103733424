.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-form {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  padding-top: 40px; /* Add space for the close button */
}

.contact-form h2 {
  color: #141213;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  margin-top: 0;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  color: #141213;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #d1d1c6;
  border-radius: 4px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #999;
  font-style: italic;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #088ba0;
}

.contact-form textarea {
  height: 100px;
  resize: vertical;
}

.contact-form button {
  background-color: #ff5910;
  color: #FFFFFF;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-transform: uppercase;
}

.contact-form button:hover {
  background-color: #e64d0e;
}

.close-button-container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  z-index: 10;
}

.close-button {
  width: 100%;
  height: 100%;
  background-color: #ff5910;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
  background-color: #e64d0e;
}

/* Remove .close-button-container entirely */

@media (max-width: 480px) {
  .contact-form {
    padding: 20px;
  }
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}