.cookie-consent {
  background-color: #ffbdae;
  color: #FFFFFF;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
}

.cookie-consent p {
  margin: 0;
  flex-grow: 1;
}

.cookie-actions {
  display: flex;
  align-items: center;
}

.cookie-consent button {
  background-color: #ff5910;
  color: #FFFFFF;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.cookie-consent a {
  color: #ff5910;
  text-decoration: underline;
  font-weight: bold;
}